@import 'imports';
$image-size: 200px;

.container {
  @extend .column,
  .bottom,
  .start,
  .full-width,
  .p-bottom-1,
  .p-top-2;

  border-bottom: 2px solid $tundora;

  &.focused {
    border-bottom: 2px solid $robins-blue;
  }
}

.file-input {
  @extend .full-width;
}

.title-label {
  @extend .ellipsis,
  .full-width,
  .m-bottom-1;

  color: $tundora;
  font-size: 14px;
}

.file-container {
  @extend .full-width;
}

.image-container {
  max-height: $image-size;
  max-width: $image-size;
}

.image {
  @extend .full-width,
  .full-height;
}

.file-preview-container {
  @extend .full-width,
  .m-top-1;
}

.file-title {
  color: $tundora;
  font-size: 14px;
  margin: 10px 0 5px;
}
