@import 'imports';

.input-content {
  @extend .column, .bottom, .start, .full-width, .p-bottom-1, .p-top-2;

  border-bottom: 2px solid $tundora;

  &.focused {
    border-bottom: 2px solid $robins-blue;
  }
}

.input-content-error {
  border-bottom: 2px solid $torch-red;
}

.placeholder {
  @extend .ellipsis, .full-width;

  color: $tundora;
  min-height: 23px;
  position: relative;
  top: 20px;
  transition: all 100ms ease;

  &.with-value {
    @extend .small-text;

    left: -1px;
    top: 0;

    &.area {
      top: 0;
    }
  }

  &.focused {
    color: $robins-blue;
  }
}

.area-container {
  margin-top: 25px;
}

.content-container {
  @extend .row, .full-width;
}

.measure {
  margin-right: 4px;
}

.date {
  @extend .full-width, .row, .top, .start;
}

.date::-webkit-datetime-edit {
  display: none;
}

.date::-webkit-calendar-picker-indicator {
  width: 300px;
}
