@import 'variables/colors';

.badge-green {
  background-color: $bermuda;
}

.badge-red {
  background-color: $shilo;
}

.badge-yellow {
  background-color: $drover;
}

.default-badge {
  background-color: $gray;
}
