@import 'imports';

.select-column {
  @extend .column,
  .full-height,
  .bottom,
  .end;
}

.select-row-inner-container {
  @extend .row,
  .full-width,
  .center,
  .middle,
  .m-top-3;
}

.field-row-with-manual {
  @extend .row,
  .end,
  .bottom;

  margin-top: -18px;
}

.select-without-manual {
  @extend .m-top-2;
}
