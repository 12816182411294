@import 'imports';

.tasting-notes-form {
  max-height: 900px;
}

.sub-step-title {
  @extend .modal-title;

  margin: 5px 0;
}

.delete {
  @extend .middle;

  padding-top: 6px;
  size: 10px;
}
