@import 'imports';

.container {
  @extend .row,
  .center,
  .middle;

  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
}
