@import 'imports';

.container {
  @extend .row,
  .full-width,
  .full-height;
}

.select {
  @extend .row,
  .full-width;

  border-bottom: 2px solid $tundora;
  padding: 10px 0 5px;

  &.focused {
    border-bottom: 2px solid $robins-blue;
  }
}

.select-error {
  border-bottom: 2px solid $torch-red;
}
