@import 'variables/colors';

@import 'layout';

@import 'fonts';
$icon-size: 36px;

.clickable {
  cursor: pointer;
}

.main-button-container {
  @extend .row,
  .full-width,
  .center,
  .middle,
  .main-button-title,
  .clickable;

  border-radius: 12px;
  height: 60px;
  text-transform: uppercase;

  &:hover {
    background-color: $java;
    color: $white;
  }
}

.primary-button {
  @extend .main-button-container;

  background-color: $blue-chill;
  color: $white;

  &.selected,
  &.selected:hover {
    background-color: $blue-chill;
  }
}

.secondary-button {
  @extend .main-button-container;

  background-color: $white;
  border: 2px solid $blue-chill;
  color: $blue-chill;

  &:hover {
    border: 2px solid $java;
  }

  &.selected,
  &.selected:hover {
    background-color: $white;
  }
}

.tertiary-button {
  @extend .main-button-title,
  .clickable;

  color: $blue-chill;

  &:hover {
    color: $electric-violet;
  }

  &.selected,
  &.selected:hover {
    color: $blue-chill;
  }
}

.disabled-button {
  cursor: auto;
  opacity: 0.5;
}

.primary-disabled-button {
  @extend .disabled-button;

  &:hover {
    background-color: $blue-chill;
  }
}

.secondary-disabled-button {
  @extend .disabled-button;

  &:hover {
    background-color: $white;
  }
}

.tertiary-disabled-button {
  @extend .disabled-button;

  &:hover {
    color: $blue-chill;
  }
}

.rounded-svg-button {
  @extend .row,
  .center,
  .middle;

  background-color: $santas-gray;
}

.link-button {
  @extend .small-title;

  color: $blue;
}

.tag {
  @extend .subtitle-no-weight,
  .regular,
  .row,
  .middle;

  background-color: rgba($blue, 0.2);
  border-radius: 27px;
  height: 30px;
  padding: 10px 15px;
}

.clickeable-icon {
  @extend .row,
  .center,
  .middle;

  border-radius: 50%;
  height: $icon-size;
  width: $icon-size;

  &:hover {
    background-color: $french-pass;
    box-shadow: 1px 1px 5px rgba($biscay, 0.3);
  }
}
