$transparent: transparent;
$white: #fff;
$black: #000;
$black-tooltip: #222;
$blue: #06f;
$azure: #35599f;
$valencia: #d34836;
$silver: #c5c5c5;
$gray-default: #808080;
$light-gray: #ccc;
$whisper: #eee;
$navbar-color: #f9f9f9;
$dove-gray: #c2d1e2;
$zerf-black: #181818;
$zerf-violet: #8640ff;
$java: #1ecaab;
$blue-chill: #117f8d;
$brand-primary: var(--brand-primary);
$santas-gray: #969bab;
$torch-red: #ff003e;
$light-green: #d9f5ed;
$heliotrope: #ad7dff;
$curious-blue: #167ee6;
$dark-azure: #3d4da6;
$tundora: #484848;
$electric-violet: #6e20f5;
$aqua-haze: #f2f7f7;
$blumine: #165f75;
$oxford-blue: #374151;
$biscay: #174463;
$french-pass: #b7fef8;
$adelphi-black: #1f2937;
$adelphi-primary: #1a3f5e;
$tuna: #353536;
$clear-silver: #c4c4c4;
$robins-blue: #07cfbe;
$bermuda: #85dec5;
$shilo: #ebb7b7;
$gray: #6b7280;
$drover: #fafcab;
$comet: #4f566b;
$ghost: #cdd1d7;
$dark-oxford-blue: #344454ad;
$alto: #ddd;
$scorpion: #606060;
$cornflower-blue: #3b5998;
$medium-red-violet: #c32aa3;
$dodger-blue: #1da1f2;
$alizarin-crimson: #e52d27;
$cod-gray: #1e1d1c;
$copper: #c0853b;
$silver-sand: #c8c9ca;
$tuatara: #282826;
$cabbage-pont: #3c4e39;
$porcelain: #f6f7f8;
$shark: #212529;
$science-blue: #0a66c2;
$blue-diane: #233c51;
$st-tropaz: #2d5c9b;
$ship-cove: #6d79b5;
$blue-bell: #a197cd;
$prelude: #d0bae4;
$lavender-gray: #c4bada;
$white-pointer: #fae0fd;
