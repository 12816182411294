@import 'variables/colors';

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px $white inset;
}

input[type="number"] { 
  -moz-appearance: textfield; 
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.default-container {
  @extend .column, .full-width, .m-bottom-2;
}

.input-label {
  @extend .subtitle-no-weight, .regular, .m-bottom-2;
}

.input-container {
  @extend .row, .middle, .subtitle-no-weight, .light, .full-width;

  box-sizing: border-box;
  padding: 5px 15px;
  border-bottom: 3px solid $santas-gray;
  border-radius: 6px 6px 3px 3px;
  background: $light-gray;

  &.input-error {
    border-color: $valencia;
  }

  &.input-ok {
    border-color: $blue;
  }

  &.no-border {
    border-bottom: none;
  }
}

.text-area {
  @extend .input-container;
  height: auto !important;
  min-height: 96px;
}

.input-container::placeholder {
  color: $zerf-black;
}

.input-container:hover {
  background: $navbar-color;
}

.input-container:focus-within {
  border-color: $zerf-black;
  background-color: $navbar-color;

  &.input-error {
    border-color: $valencia;
  }

  &.input-ok {
    border-color: $blue;
  }
}

.input {
  @extend .full-width, .full-height;

  background: none !important;
}

.area {
  @extend .full-width;
  display: flex;
  overflow: visible;
  box-sizing: border-box;
  min-height: 100%;
  resize: none;
  background: none !important;
}

.input-autofill {
  -webkit-box-shadow: 0 0 0 30px $silver inset !important;
}

.input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px $gray-default inset !important;
}

.input:-webkit-autofill:focus,
.input:-webkit-autofill:active,
.input:-webkit-autofill:hover {
  @extend .input-autofill;
}

.input-container:hover {
  input:-webkit-autofill {
    @extend .input-autofill;
  }
}

.input-content:hover {
  input:-webkit-autofill {
    @extend .input-autofill;
  }
}

.input-container:focus-within {
  .input:-webkit-autofill {
    @extend .input-autofill;
  }
}
