@import 'variables/variables';

@import 'variables/colors';

.unlabeled-field {
  margin-top: 30px;
}

.full-width {
  width: 100%;
}

.three-quarter-width {
  width: 75%;
}

.half-width {
  width: 50%;
}

.quarter-width {
  width: 25%;
}

.full-height {
  height: 100%;
}

.half-height {
  height: 50%;
}

@media screen and (max-width: $breakpoint-sm) {
  .full-width-mobile {
    width: 100%;
  }
}

.bottle-divider {
  @extend .row,
  .full-width;

  border-top: 1px solid $silver-sand;
  margin: 30px auto;
  max-width: 100px;
}
