@import 'imports';

.container {
  @extend .row, .full-width, .center;

  gap: 20px;
}

.field-container {
  @extend .column, .full-height, .center, .middle;

  width: 50%;
}

.one-field-container {
  @extend .column;

  padding-right: 10px;
}
