@import 'imports';

.adelphi-field-container {
  @extend .column,
  .full-width;

  margin: 5px 0;
}

.adelphi-field-container-no-margin {
  margin: 0;
}

.inner-container {
  @extend .row,
  .full-width;
}

.adelphi-label {
  @extend .inner-container,
  .field-label;
}
