@import './variables/breakpoints';

@import './variables/colors';

// Font weights

.bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 600;
}

.medium {
  font-weight: 500;
}

.regular {
  font-weight: 400;
}

.light {
  font-weight: 300;
}

// Format text

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-justify {
  text-align: justify;
}

.line-through {
  text-decoration: line-through;
}

.large-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
}

.large-title-no-weight {
  font-size: 22px;
  line-height: 33px;
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.title-2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.title-2-no-weight {
  font-size: 18px;
  line-height: 24px;
}

.small-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
}

.subtitle {
  font-size: 16px;
  font-weight: 200;
  line-height: 21px;
}

.subtitle-no-weight {
  font-size: 16px;
  line-height: 21px;
}

.subtitle-2 {
  font-size: 16px;
  font-weight: 200;
  line-height: 21px;
}

.subtitle-2-no-weight {
  font-size: 16px;
  line-height: 21px;
}

.large-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.large-text-no-weight {
  font-size: 14px;
  line-height: 24px;
}

.text {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.text-no-weight {
  font-size: 14px;
  line-height: 16px;
}

.small-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
}

.small-text-no-weight {
  font-size: 12px;
  line-height: 21px;
}

.smaller-text {
  font-size: 14px;
  @include md {
    font-size: 12px;
  }
  @include sm {
    font-size: 11px;
  }
  line-height: 18px;
}

.underline {
  text-decoration: underline;
}

.error {
  @extend .small-text;

  color: $valencia;
  padding-top: 5px;
}

.main-title {
  font-size: 24px;
  line-height: 24px;
}

.main-button-title {
  @extend .semi-bold;

  font-size: 16px;
  line-height: 24px;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin max-lines($max) {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: $max;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal-title {
  @extend .main-title,
  .semi-bold,
  .text-center;

  color: $blumine;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.field-label {
  @extend .semi-bold;

  color: $blumine;
  font-size: 18px;
  line-height: 24px;
}

.substep-column-title {
  @extend .field-label,
  .text-center;
}

.column-title {
  @extend .row,
  .full-width,
  .center,
  .substep-column-title,
  .m-top-2;

  margin-bottom: -10px;
}

.row-title {
  @extend .field-label;

  text-align: center;
}
